.parentdiv {
  width: 500px;
  height: 350px;
  overflow: hidden;
}

.overlaydiv {
  padding: 5px 10px;
  background-color: transparent;
  bottom: 6%;
  margin: 0 auto;
}

.overlaydiv2{
  background-color:rgb(235, 235, 235);
  padding: 5px;
  bottom:10px;
  right:10px;
  max-width:250px;
  max-height:80px;
  overflow:hidden;
  border-radius: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

#audio {
  background-color: green;
  min-width: 100px;
}

#video {
  background-color: blue;
}

#endcall {
  background-color: red;
}
